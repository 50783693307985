<template>
  <div>
    <title>SIM INVENTORY ~ PURCHASE ORDER DETAILS LIST DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Purchase Order Detail List Data
        <br />
        <h4>
          The following is a list of transaction data lists for purchase order
          details
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <!-- <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Master Item Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-3">
                    Show &nbsp; :
                    <select
                      v-model="pageSize2"
                      @change="handlePageSizeChange2($event)"
                    >
                      <option
                        v-for="size2 in pageSizes2"
                        :key="size2"
                        :value="size2"
                      >
                        {{ size2 }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by item code"
                      class="form-control"
                      v-model="namesearch2"
                    />
                  </div>
                  <div class="col-xs-1">
                    <button
                      @click="carilistGridBrg()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isExist2" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Unit</th>
                      <th>Item Type</th>
                      <th>Notes</th>
                      <th>Qty</th>
                      <th>Price</th>
                      <th>Unit Price</th>
                      <th>Qty PO</th>
                      <th>Unit PO</th>
                      <th>Convertion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classbrg, index) in list_pagedBrg"
                      :key="classbrg.id"
                    >
                      <td>
                        {{ index + 1 + (page2 - 1) * pageSize2 }}
                      </td>
                      <td>{{ classbrg.kode_barang }}</td>
                      <td>{{ classbrg.nama_barang }}</td>
                      <td>{{ classbrg.unit }}</td>
                      <td>{{ classbrg.tipe }}</td>
                      <td>
                        <input
                          :id="'inpn-' + classbrg.id"
                          type="text"
                          class="form-control"
                          v-model="classbrg.notespod"
                          :name="'nmn-' + classbrg.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpq-' + classbrg.id"
                          type="text"
                          placeholder="ex : 3"
                          required=""
                          class="form-control"
                          v-model="classbrg.qty"
                          :name="'nmq-' + classbrg.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpp-' + classbrg.id"
                          type="text"
                          placeholder="ex : 3500"
                          required=""
                          class="form-control"
                          v-model="classbrg.pricepod"
                          :name="'nmp-' + classbrg.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpu-' + classbrg.id"
                          type="text"
                          class="form-control"
                          placeholder="ex : Rp"
                          required=""
                          v-model="classbrg.unitpricepod"
                          :name="'nmu-' + classbrg.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpqp-' + classbrg.id"
                          type="text"
                          class="form-control"
                          placeholder="ex : 3"
                          required=""
                          v-model="classbrg.qtypod"
                          :name="'nmqp-' + classbrg.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpup-' + classbrg.id"
                          type="text"
                          class="form-control"
                          placeholder="ex : PCS / KG / etc."
                          required=""
                          v-model="classbrg.unitpod"
                          :name="'nmup-' + classbrg.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpc-' + classbrg.id"
                          type="text"
                          class="form-control"
                          placeholder="ex : 1 / 2 / 3"
                          required=""
                          v-model="classbrg.konversi"
                          :name="'nmc-' + classbrg.id"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <v-pagination
                  v-model="page2"
                  :pages="count2"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange2"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>No.</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Unit</th>
                      <th>Item Type</th>
                      <th>Notes</th>
                      <th>Qty</th>
                      <th>Price</th>
                      <th>Unit Price</th>
                      <th>Qty PO</th>
                      <th>Unit PO</th>
                      <th>Convertion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="13" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <button @click="saveData()" type="button" class="btn btn-primary">
            <i class="fa fa-save"></i>
            Save
          </button>
          &nbsp;
          <router-link to="/po">
            <button class="btn btn-success">
              <i class="fa fa-angle-double-left"></i> Back
            </button>
          </router-link>
          <br /><br />
        </div>
      </div> -->

      <div class="row">
        <div class="col-xs-12">
          <div class="box box-primary">
            <div class="box-header">
              <i class="fa fa-list"></i>
              <h3 class="box-title">
                Purchase Order Detail List Data
                <b style="color: red">{{ ponumber }}</b>
              </h3>
            </div>

            <div class="box-body">
              <div class="box-body">
                <div class="row">
                  <div class="col-md-3">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-md-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Item Code"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-md-1">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <input
                type="hidden"
                class="form-control"
                readonly
                name="ponumber"
                v-model="ponumber"
                id="ponumber"
              />
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Code</th>
                      <th>Qty</th>
                      <th>Price</th>
                      <th>Notes</th>
                      <!-- <th>Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                      <td>{{ classdata.price }}</td>
                      <td>{{ classdata.notes }}</td>
                      <!-- <td>
                        <router-link :to="'/edit-po-detail/' + classdata.id">
                          <button title="Edit PO Detail" class="btn btn-info">
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link>
                        &nbsp;
                        <button
                          title="Delet List PO Detail"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Code</th>
                      <th>Qty</th>
                      <th>Price</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="6" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>

      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination,
  },
  data() {
    return {
      isShow: false,
      isExist: true,
      isExist2: true,
      loading: false,
      namesearch: "",
      namesearch2: "",
      list_paged: [],
      list_pagedBrg: [],
      page: 1,
      page2: 1,
      pagestat: 1,
      count: 1,
      count2: 1,
      pageSize: 10,
      pageSize2: 10,
      ponumber: "",
      pageSizes: [10, 50, 100, 500],
      pageSizes2: [10, 50, 100, 500],
    };
  },
  created() {
    this.fetchData();
    this.listGridBrg();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if(this.namesearch == '') {
        this.fetchData();
      } else {
        this.searchdata()
      }
    },
    handlePageChange2(value) {
      this.page2 = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.listGridBrg();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if(this.namesearch == '') {
        this.fetchData();
      } else {
        this.searchdata()
      }
    },
    handlePageSizeChange2(event) {
      this.pageSize2 = event.target.value;
      this.page2 = 1;
      this.listGridBrg();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },

    async listGridBrg() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "barang/getalldatabarang?length=" +
        this.pageSize2 +
        "&page=" +
        this.page2;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist2 = false;
          } else {
            this.isExist2 = true;
            localStorage.setItem("dtmaterial", JSON.stringify(resp.data.data));
            this.list_pagedBrg = resp.data.data;
            this.count2 = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    async carilistGridBrg() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "barang/getalldatabarang?length=" +
        this.pageSize2 +
        "&cari=" +
        this.namesearch2;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist2 = false;
          } else {
            this.isExist2 = true;
            localStorage.setItem("dtmaterial", JSON.stringify(resp.data.data));
            this.list_pagedBrg = resp.data.data;
            this.count2 = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    async saveData() {
      this.loading = true;
      const kodeuser = sessionStorage.getItem("kodeuser");
      const datagetGridBrg = JSON.parse(
        localStorage.getItem("dtmaterial") || "[]"
      );

      for (let i in datagetGridBrg) {
        const paramdatadetail = {
          po_number: this.ponumber,
          kode_barang: datagetGridBrg[i].kode_barang,
          notes: document.getElementById("inpn-" + datagetGridBrg[i].id).value,
          qty: document.getElementById("inpq-" + datagetGridBrg[i].id).value,
          price: document.getElementById("inpp-" + datagetGridBrg[i].id).value,
          unit_price: document.getElementById("inpu-" + datagetGridBrg[i].id)
            .value,
          unit: datagetGridBrg[i].unit,
          qty_po: document.getElementById("inpqp-" + datagetGridBrg[i].id)
            .value,
          unit_po: document.getElementById("inpup-" + datagetGridBrg[i].id)
            .value,
          konversi: document.getElementById("inpc-" + datagetGridBrg[i].id)
            .value,
          kode_user: kodeuser,
        };
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIsavedatadet = this.$apiurl + "po_detail/savepo_detail";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin,
        };

        if (
          Number(paramdatadetail.qty) > 0 &&
          Number(paramdatadetail.price) > 0 &&
          paramdatadetail.unit_price != "" &&
          Number(paramdatadetail.qty_po) > 0 &&
          paramdatadetail.unit_po != "" &&
          Number(paramdatadetail.konversi) > 0
        ) {
          axios
            .post(urlAPIsavedatadet, paramdatadetail, { headers })
            .then(() => {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false,
              });
              this.loading = false;
              this.fetchData();
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
                showConfirmButton: false,
              });
              console.log(err);
              this.loading = false;
            });
        }
      }
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_h/getdaftar_order_hbyid?id=" + this.$route.params.id;
      const urlAPIget =
        this.$apiurl + "po_header/getpo_headerbyid?id=" + this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.ponumber = resp.data.data.po_number;

          // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/po_detail/getalldatapo_detail?length="+this.pageSize+"&page="+this.page+"&po_number="+this.ponumber
          const urlApiGETdetail =
            this.$apiurl +
            "po_detail/getalldatapo_detail?length=" +
            this.pageSize +
            "&page=" +
            this.page +
            "&po_number=" +
            this.ponumber;
          axios.get(urlApiGETdetail, { headers }).then((respdetail) => {
            // console.log(respdetail)
            if (respdetail.data.data.length == 0) {
              this.isExist = false;
            } else {
              this.isExist = true;
              this.list_paged = respdetail.data.data;
              this.count = respdetail.data.datatotalcount;
            }
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    searchdata() {
      this.loading = true;
      const params = this.getRequestParams(this.page);
      //const params = this.getRequestParams(this.pagestat);
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_h/getdaftar_order_hbyid?id=" + this.$route.params.id;
      const urlAPIget =
        this.$apiurl + "po_header/getpo_headerbyid?id=" + this.$route.params.id;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          this.ponumber = resp.data.data.po_number;
          // const urlAPIgetdet = "po_detail/getalldatapo_detail?length="+this.pageSize+"&page="+this.page+"&cari="+this.namesearch+"&nomor_order="+this.nomeroderh
          const urlAPIgetdet =
            this.$apiurl +
            "po_detail/getalldatapo_detail?length=" +
            this.pageSize +
            "&page=" +
            this.page +
            "&cari=" +
            this.namesearch +
            "&po_number=" +
            this.ponumber;

          axios
            .get(urlAPIgetdet, {
              params,
              headers: { Authorization: tokenlogin },
            })
            .then((respn) => {
              if (respn.data.data.length == 0) {
                this.isExist = false;
              } else {
                this.isExist = true;
                this.list_paged = respn.data.data;
                this.count = respn.data.datatotalcount;
              }
            });
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_d/hapusdaftar_order_d/" + id;
          const urlAPIbrgdel = this.$apiurl + "po_detail/hapuspo_detail/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin,
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully",
                }).then(function () {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data gagal",
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
